<template>
  <div id="whatsapp">
    <a href="https://wa.me/212665663933" target="_blank" id="toggle1" class="wtsapp">
      <i class="fa fa-whatsapp"></i>
    </a>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style>
  #whatsapp .wtsapp{
    position: fixed;
    transform: all .5s ease;
    background-color: #25d366;
    display: block;
    text-align: center;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    border-radius: 50px;
    border-right: none;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    bottom: 70px;
    left: 20px;
    border: 0;
    z-index: 9999;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  #whatsapp .wtsapp:before{
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
  }
  #whatsapp .wtsapp:focus{
    border: none;
    outline: none;
  }

  @keyframes pulse-border {
    0%{transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1); opacity: 1;}
    100%{transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5); opacity: 0;}
  }
</style>