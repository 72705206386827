<template>
  <div class="destination-search">
    <div class="search-options-container">
      <div class="search-option">
        <div class="icon-container">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <select name="location" id="location">
          <option value="">Marrakech</option>
          <option value="">Essaouira</option>
          <option value="">Chefchaouen</option>
          <option value="">Agadir</option>
          <option value="">Ouarzazat</option>
          <option value="">Sahara Desert</option>
          <option value="">Fes</option>
          <option value="">Casablanca</option>
          <!-- Add your location options here -->
        </select>
      </div>
      <div class="search-option">
        <div class="icon-container">
          <i class="fas fa-users"></i>
        </div>
        <input
          type="number"
          name="persons"
          id="persons"
          v-model.number="numberOfPersons"
          max="50"
        />
      </div>
      <div class="search-option">
        <div class="icon-container">
          <i class="fas fa-calendar-alt"></i>
        </div>
        <input type="date" name="date" id="date" v-model="currentDate" />
      </div>
      <button @click="submitSearch">Search</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numberOfPersons: 1,
      currentDate: "",
    };
  },
  watch: {
    numberOfPersons(newValue) {
      if (newValue > 50) {
        this.numberOfPersons = 50;
      }
    },
  },
  created() {
    // Set the current date in the format YYYY-MM-DD
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    this.currentDate = `${year}-${month}-${day}`;
  },
  methods: {
    submitSearch() {
      // Handle search submission
      alert("submitted");
    },
  },
};
</script>

<style>
.destination-search {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.search-options-container {
  background-color: #f6f6f7;
  padding: 5px 10px 5px 5px; /* Adjust padding as needed */
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.search-option {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust spacing between dropdowns */
}

.search-option:not(:last-child)::after {
  content: "|";
  margin-left: 10px; /* Adjust spacing between separation lines */
  color: #888; /* Color of the separation lines */
}

.search-option select,
.search-option input {
  padding: 15px 15px;
  border: 1px solid #ccc;
  color: #878a8f;
  border-radius: 5px;
  border: none;
  background-color: transparent; /* Set background to transparent */
}

.icon-container {
  margin: 0 15px; /* Adjust spacing between icon and input/select */
}

.icon-container i {
  color: #888; /* Color of the icons */
}

.destination-search button {
  background-color: #de8738;
  font-size: 0.8rem;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}
/* Hide the default dropdown arrow */
.search-option select {
  -webkit-appearance: none; /* Remove default arrow for WebKit browsers */
  -moz-appearance: none; /* Remove default arrow for Firefox */
  appearance: none; /* Remove default arrow for other browsers */
}
/* Hide the default date calendar icon */
.search-option input[type="date"]::-webkit-calendar-picker-indicator {
  display: none; /* Hide the default calendar icon for WebKit browsers */
}
/* Responsive styles */
@media (max-width: 768px) {
  .search-options-container {
    flex-direction: column;
    padding: 20px;
  }

  .search-option {
    margin-bottom: 10px;
    width: 100%;
    border-bottom:1px solid #000;
    border-width: 50%;
  }

  .search-option:not(:last-child)::after {
    content: "";
    margin: 0;
  }

  .icon-container {
    margin: 0 10px 0 2px;
  }

  .destination-search button {
    width: 100%;
    font-size: 1rem;
    padding: 12px;
  }
}
</style>
