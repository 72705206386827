<template>
  <div class="filter-nav">
    <button
      v-for="category in categories"
      :key="category"
      @click="filterByCategory(category)"
      :class="{ active: activeCategory === category}"
    >
    {{ category.charAt(0).toUpperCase() + category.slice(1) }}
  </button>
  </div>
</template>

<script>
export default {
  data() {
    return{
      activeCategory: 'All',
      categories: ['All', 'recommend', 'beach', 'nature', 'monuments', 'souk', 'desert']
    }
  },
  methods: {
    filterByCategory(category) {
      this.activeCategory = category;
      this.$emit('filter', category);
    }
  }
}
</script>

<style>
  .filter-nav{
    margin: 20px 0;
  }
  .filter-nav button{
    color: #000;
    font-weight: 700;
    background-color: #f6f6f7;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 50px;
  }
  .filter-nav button:hover{ 
    cursor: pointer;
  }
  .filter-nav button.active {
    color: #fff;              /* Text color */
    background-color: #de8738; /* Background color */
  }
</style>