<template>
  <div class="top-things">
    <!-- First Row -->
    <div class="first-row">
      <h2 class="title">Top Things to Do by Category</h2>
      <button class="btn" @click="goActivity()">Browse All</button>
    </div>
    <!-- Second Row -->
    <div class="second-row">
      <h5 class="subtitle">Tours and activities tailored to your interests</h5>
      <!-- Four Columns -->
      <div class="columns" ref="columns">
        <div
          v-for="(item, index) in displayedItems"
          :key="index"
          class="column"
          @mouseenter="animateEnter"
          @mouseleave="animateLeave"
        >
          <img :src="item.url" :alt="item.alt" class="image_things" />
          <h4 class="card-title">{{ item.title }}</h4>
          <p class="card-category">{{ item.category }}</p>
        </div>
        <!-- Navigation arrow -->
        <div class="navigation-arrow" @click="nextItems">
          <i class="fa-solid fa-arrow-right thing_to_do_arrow"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThingsToDo",
  data() {
    return {
      items: [
        {
          url: require("@/assets/buggy_1.jpg"),
          alt: "Image 1",
          title: "Desert Buggy Adventure",
          category: "Adventure",
        },
        {
          url: require("@/assets/camel_1.jpg"),
          alt: "Image 2",
          title: "Explore Marrakech",
          category: "Cultural Experience",
        },
        {
          url: require("@/assets/quad_2.jpg"),
          alt: "Image 3",
          title: "Quad Biking",
          category: "Adventure",
        },
        {
          url: require("@/assets/balloon_3.jpg"),
          alt: "Image 4",
          title: "Hot Air Balloon Ride",
          category: "Aerial Adventure",
        },
        {
          url: require("@/assets/karting.jpg"),
          alt: "Image 5",
          title: "Karting",
          category: "Motorsport",
        },
        {
          url: require("@/assets/paintball.jpg"),
          alt: "Image 6",
          title: "Paintball",
          category: "Team Sport",
        },
        {
          url: require("@/assets/paragliding.jpg"),
          alt: "Image 7",
          title: "Paragliding",
          category: "Aerial Adventure",
        },
        {
          url: require("@/assets/tourGuide.jpg"),
          alt: "Image 8",
          title: "Guided tours",
          category: "Cultural Experience",
        },
      ],
      currentIndex: 0,
      itemsPerPage: 4,
    };
  },
  computed: {
    displayedItems() {
      const endIndex = this.currentIndex + this.itemsPerPage;
      return this.items.slice(this.currentIndex, endIndex);
    },
  },
  methods: {
    nextItems() {
      if (this.currentIndex + this.itemsPerPage < this.items.length) {
        this.currentIndex += this.itemsPerPage;
      } else {
        this.currentIndex = 0; // Loop back to the first set of items
      }
    },
    goActivity() {
      this.$router.push("/activities");
    },
    animateEnter(event) {
      event.target.classList.add("hover-animation");
    },
    animateLeave(event) {
      event.target.classList.remove("hover-animation");
    },
    updateItemsPerPage() {
      if (window.innerWidth <= 768) {
        this.itemsPerPage = 3;
      } else {
        this.itemsPerPage = 4;
      }
    },
  },
  mounted() {
    this.updateItemsPerPage();
    window.addEventListener("resize", this.updateItemsPerPage);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateItemsPerPage);
  },
};
</script>

<style>
.top-things {
  background-color: #f2f2f2;
  padding: 10px 0;
}
.subtitle {
  margin-bottom: 2%;
}
.first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
}
.second-row {
  margin: 0 30px;
}
.columns {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.column {
  flex-basis: 22%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.column:hover {
  transform: scale(1.05);
}

.card-title {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

.card-category {
  font-size: 1rem;
  color: #777;
  text-align: center;
}

.column img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.btn {
  background-color: orange;
  border-radius: 50px;
  padding: 10px 20px;
  font-weight: bold;
  border: none;
  margin-top: 5px;
  cursor: pointer;
}

.image_things {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.navigation-arrow {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  cursor: pointer;
}

.thing_to_do_arrow {
  background-color: orange;
  border-radius: 50%;
  padding: 10px;
  border: 2px solid #fff;
}

/* Animation */
.hover-animation {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
  .first-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .first-row .btn {
    margin-top: 10px;
    width: 40%;
    text-align: center;
  }

  .second-row {
    margin: 20px 15px;
  }

  .columns {
    justify-content: space-around;
  }

  .column {
    flex-basis: 30%;
  }

  .column img {
    height: 200px;
  }

  .navigation-arrow {
    right: -15px;
  }

  .thing_to_do_arrow {
    padding: 15px;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-category {
    font-size: 0.9rem;
  }
}
</style>
