<template>
  <div class="modal-overlay" v-if="show" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-button" @click="closeModal">&times;</button>
      <div class="modal-body">
        <div class="left-section">
          <p class="pathUrl">
            <em>Activities / Category / {{ activity.category }}</em>
          </p>
          <h2>{{ activity.title }}</h2>
          <p>{{ activity.description }}</p>
        </div>
        <div class="center-section">
          <img
            :src="getImageUrl(mainImage)"
            alt="Main Image"
            class="main-image"
          />
        </div>
        <div class="right-section">
          <div class="additional-images">
            <img
              v-for="(image, index) in activity.additionalImages"
              :key="index"
              :src="getImageUrl(image)"
              alt="Thumbnail"
              class="thumbnail-image"
              @click="swapImage(index)"
            />
          </div>
          <p class="price">{{ activity.price }}</p>
          <button class="reservation-button" @click="reserveActivity">
            Reserve Now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalActivity",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainImage:
        this.activity && this.activity.image ? this.activity.image : "",
    };
  },
  watch: {
    activity: {
      handler(newActivity) {
        this.mainImage =
          newActivity && newActivity.image ? newActivity.image : "";
      },
      immediate: true,
    },
    show(newVal) {
      if (newVal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    reserveActivity() {
      // Implement reservation logic
      console.log("Reserving activity:", this.activity.title);
    },
    getImageUrl(imagePath) {
      try {
        return require(`@/assets/${imagePath}`);
      } catch (e) {
        console.error(`Cannot find module './${imagePath}'`);
        return "";
      }
    },
    swapImage(index) {
      const temp = this.mainImage;
      this.mainImage = this.activity.additionalImages[index];
      this.activity.additionalImages.splice(index, 1, temp);
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
  max-width: 1200px;
  position: relative;
}

.close-button {
  background: none;
  border: none;
  font-size: 2rem;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
}

.left-section {
  flex: 1;
  padding: 20px;
  text-align: left;
  min-width: 250px;
}

.pathUrl {
  color: gray;
  font-size: 0.8rem;
}

.left-section h2 {
  margin: 20px 0;
}

.center-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-width: 250px;
}

.right-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
}

.main-image {
  max-width: 100%;
  border-radius: 10px;
}

.additional-images {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.thumbnail-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  margin: 10px 0;
}

.reservation-button {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  padding: 10px 30%;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}

.reservation-button:hover {
  animation: sh0 0.5s ease-in-out both;
}

/* Responsive styles */
@media (max-width: 768px) {
  .modal-body {
    flex-direction: column;
    padding: 10px;
  }

  .left-section,
  .center-section,
  .right-section {
    min-width: 100%;
    padding: 10px;
  }

  .main-image {
    width: 80%;
    height: auto;
  }

  .additional-images {
    justify-content: center;
  }

  .thumbnail-image {
    width: 75px;
    height: 75px;
  }

  .price {
    font-size: 1.5rem;
  }

  .reservation-button {
    padding: 10px 20%;
  }
}
</style>
