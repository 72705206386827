<template>
  <Navbar />
  <div id="main-content">
    <router-view />
  </div>
  <Footer />
  <WhatsappIcon />
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import WhatsappIcon from "@/components/WhatsappIcon.vue";

export default {
  components: {
    Navbar,
    Footer,
    WhatsappIcon,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "Poppins";
}
.cta-button {
  width: 60%;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  margin: 5% 0 8% 0;
  padding: 20px 0;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
}
.cta-button:hover {
  animation: sh0 0.5s ease-in-out both;
}

#main-content {
  /* Ensure the main content takes enough space to push footer down */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
