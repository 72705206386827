<template>
  <div class="card">
    <div class="image-container">
      <img
        :src="getImageUrl(image.url)"
        alt="Destination Image"
        class="card-image"
      />
    </div>
    <div class="card-rating">{{ image.rating }}</div>
    <div class="card-price">{{ image.price }}</div>
    <div class="card-details">
      <div class="card-city">{{ image.city }}</div>
      <div class="card-place">{{ image.place }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: Object, // Image object containing details like url, rating, price, city, and place
  },
  methods: {
    // Method to get the image URL from the assets folder
    getImageUrl(imagePath) {
      return require(`@/assets/moroccan_destinations/${imagePath}`);
    },
  },
};
</script>

<style>
.card {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}

.image-container {
  width: 100%;
  height: 0;
  padding-top: 100%; /* Maintain 4:3 aspect ratio for the image */
  position: relative;
  overflow: hidden;
}
.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
}

.card-rating {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 50%;
}

.card-price {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
}

.card-details {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
}

.card-city {
  font-weight: bold;
}

.card-place {
  margin-top: 5px;
}
</style>
