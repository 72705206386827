<template>
  <header :class="{ navbar: true, 'navbar-scrolled': !atTop }">
    <div class="logo">
      <!-- Your logo or brand name -->
      <p @click="goHome()" class="nav-logo">
        <span class="first-part-logo">NOMAD</span> THRILLS
      </p>
    </div>

    <!-- Show hamburger icon when width is less than 1100px -->
    <div v-show="showHamburger" class="hamburger" @click="toggleNav">
      <i class="fa-solid fa-bars-staggered custom-icon"></i>
    </div>

    <!-- Show navbar links when width is greater than or equal to 1100px -->
    <ul v-show="!showHamburger" class="nav-links">
      <!-- Your navigation links -->
      <li><router-link to="/">Discover</router-link></li>
      <li>
        <router-link to="/activities">Activities</router-link>
      </li>
      <li>
        <router-link to="/destinations">Destinations</router-link>
      </li>
      <li><router-link to="/contact">Contact Us</router-link></li>
      <li>
        <button @click="openFormModal" id="cta-button-navbar">
          Get Started
        </button>
      </li>
      <!-- <li>
        <router-link to="/contact" @click="openFormModal" id="cta-button-navbar"
          >Get Started</router-link
        >
      </li> -->
    </ul>

    <!-- Sidebar navigation -->
    <aside
      v-show="showSidebar"
      :class="{ sidebar: true, 'sidebar-open': showSidebar }"
    >
      <!-- Logo -->
      <p @click="goHome()" class="side-logo">
        <span class="first-part-logo">NOMAD</span> THRILLS
      </p>
      <!-- Your sidebar content -->
      <ul class="sidebar-links">
        <li @click="closeSidebar">
          <router-link to="/">Discover</router-link>
        </li>
        <li @click="closeSidebar">
          <router-link to="/activities">Activities</router-link>
        </li>
        <li @click="closeSidebar">
          <router-link to="/destinations">Destinations</router-link>
        </li>
        <li @click="closeSidebar">
          <router-link to="/contact">Contact Us</router-link>
        </li>
        <li @click="closeSidebar">
          <button @click="openFormModal" id="cta-button-Sidenavbar">
            Get Started
          </button>
        </li>
        <!-- <li @click="closeSidebar">
          <router-link
            to="/contact"
            @click="openFormModal"
            id="cta-button-Sidenavbar"
            >Get Started</router-link
          >
        </li> -->
      </ul>
    </aside>
    <MultiStepForm
      :show="showModal"
      :prefillData="modalData"
      @close="closeFormModal"
    />
  </header>
</template>

<script>
import MultiStepForm from "./MultiStepForm.vue";

export default {
  name: "Navbar",
  components: {
    MultiStepForm,
  },
  data() {
    return {
      atTop: true,
      showHamburger: window.innerWidth < 1100, // Initially show hamburger icon based on window width
      showSidebar: false, // Initially hide the sidebar
      showModal: false, // Control the visibility of the modal
      modalData: {}, // Data to pre-fill the form
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    handleScroll() {
      this.atTop = window.scrollY === 0;
    },
    handleResize() {
      this.showHamburger = window.innerWidth < 1100; // Update showHamburger based on window width
    },
    toggleNav() {
      // Toggle the sidebar visibility
      this.showSidebar = !this.showSidebar;
    },
    closeSidebar() {
      // Close the sidebar
      this.showSidebar = false;
    },
    openFormModal() {
      this.modalData = {}; // Clear modal data for new entry
      this.showModal = true;
    },
    closeFormModal() {
      this.showModal = false;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 90%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-radius: 0 0 10px 10px;
  z-index: 1000;
  margin: 0 auto;
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}
.navbar-scrolled {
  background-color: #fefefe;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
}
.nav-logo,
.first-part-logo,
.side-logo {
  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
  color: orange;
}
.first-part-logo {
  color: #000;
}
.nav-logo {
  font-size: 30px;
  text-transform: uppercase;
  cursor: pointer;
}
.side-logo {
  font-size: 25px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 15px 0 0 15px;
}
header ul {
  display: flex;
}
.nav-links li a {
  color: #212526;
  display: inline-block;
  margin: 0 2px;
  font-weight: 600;
  padding: 8px 18px;
  transition: 0.2s;
  border-radius: 30px;
}
header ul li a:hover {
  background: orange;
  color: #212526;
}
.router-link-active {
  background-color: #d9d9db;
  color: #000;
}
.router-link-active:hover {
  background: orange; /* Keep the background color unchanged on hover */
  color: #fff; /* Keep the text color unchanged on hover */
}
.custom-icon {
  font-size: 24px; /* Adjust the size as needed */
  cursor: pointer;
}
#cta-button-Sidenavbar {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}
#cta-button-navbar {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
}
#cta-button-navbar, #cta-button-Sidenavbar {
  padding: 10px 20px;
  border-radius: 25px;
  border: none;
}
#cta-button-navbar:hover {
  animation: sh0 0.5s ease-in-out both;
}
@keyframes sh0 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(7deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-7deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

/* =======sidebar======= */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #fefefe; /* Sidebar background color */
  z-index: 1000;
  /* transition: 0.2s; */
  transition: transform 0.3s ease; /* Smooth transition for sidebar animation */
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.05);
}

.sidebar-open {
  transform: translateX(0); /* Move the sidebar into view */
}

.sidebar-logo {
  margin: 30px 0 0 30px;
}

.sidebar-links {
  list-style: none;
  padding: 0 20px;
  margin-top: 50px;
  display: block;
}

.sidebar-links li {
  margin-bottom: 30px;
}

.sidebar-links li a {
  color: #212526; /* Sidebar link color */
  /* text-decoration: none; */
  padding: 10px 100px 10px 10px;
  border-radius: 30px;
  font-weight: bold;
}
</style>
