import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Activities from "../views/Activities.vue";
import Destinations from "../views/Destinations.vue";
import Contact from "../views/Contact.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/destinations",
    name: "destinations",
    component: Destinations,
  },
  {
    path: "/activities",
    name: "activities",
    component: Activities,
  },
  ,
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
