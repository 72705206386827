<template>
  <div class="about-container">
    <div>
      <img
        :src="require('@/assets/about_image.png')"
        alt="About Image"
        class="about-image"
      />
    </div>
    <div class="text-content">
      <h2>ABOUT</h2>
      <h1>
        We Recommend<br />
        Beautiful Destinations<br />
        Every Month
      </h1>
      <p>
        Let's choose your dream destination here We<br />
        provide many destinations and we offer the best destinations every week.
      </p>
      <div class="info-stats">
        <div class="info-stat">
          <h3>2000+</h3>
          <p>Our Explorers</p>
        </div>
        <div class="info-stat">
          <h3>100+</h3>
          <p>Destinations</p>
        </div>
        <div class="info-stat">
          <h3>20+</h3>
          <p>Years Experience</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.about-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  align-items: start;
  gap: 20px; /* Gap between the image and text content */
}

.about-image {
  width: 100%; /* Full width within its grid column */
  height: auto; /* Maintain aspect ratio */
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-content h2 {
  color: #ffcc00;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.text-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.info-stats {
  display: flex;
  gap: 40px;
}

.info-stat {
  text-align: center;
}

.info-stat h3 {
  font-size: 2rem;
  margin-bottom: 5px;
}

.info-stat p {
  font-size: 1rem;
  color: #666;
}
@media (max-width: 768px) {
  .about-container {
    display: block; /* Change to block layout on mobile */
    text-align: center; /* Center align content */
  }

  .about-image {
    width: 100%; /* Full width within its container */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure image does not exceed its container */
    margin-bottom: 20px; /* Add space below image */
  }

  .text-content {
    padding: 0 20px; /* Add padding to text content */
  }

  .text-content h1 {
    font-size: 2rem; /* Adjust font size for mobile */
    margin-bottom: 10px;
    line-height: 1.2; /* Improve line height for readability */
  }

  .info-stats {
    display: flex;
    flex-wrap: wrap; /* Wrap stats on smaller screens */
    justify-content: center; /* Center align stats */
    gap: 20px; /* Gap between stats */
  }

  .info-stat h3 {
    font-size: 1.5rem; /* Adjust font size for mobile */
    margin-bottom: 5px;
  }

  .info-stat p {
    font-size: 0.8rem; /* Adjust font size for mobile */
  }
}
</style>
