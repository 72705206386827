<template>
  <div class="contact-container">
    <div class="form-section">
      <div class="form-title">
        <h1>Contact Us</h1>
        <p>
          Feel free to contact us any time. We will<br /> get
          back to you as soon as we can!
        </p>
      </div>
      <form @submit.prevent="submitForm">
        <input
          type="text"
          v-model="name"
          placeholder="Name"
          class="contact-input"
          required
        />
        <input
          type="email"
          v-model="email"
          placeholder="Email"
          class="contact-input"
          required
        />
        <textarea
          v-model="message"
          placeholder="Message"
          class="contact-input"
          required
        ></textarea>
        <button type="submit" class="ContactBtn">SEND</button>
      </form>
    </div>
    <div class="info-section">
      <h2 class="h2-info">Info</h2>
      <ul>
        <li><i class="fa fa-envelope info-icons"></i> info@nomadthrills.com</li>
        <li><i class="fas fa-phone info-icons"></i> +212 665 663 933</li>
        <li><i class="fas fa-clock info-icons"></i> 08:00 - 21:00</li>
      </ul>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3394.830796937571!2d-7.9474429248736245!3d31.693193238584062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdaff31843c0bfd3%3A0x171d17ad028e2e6!2sNOMAD%20THRILLS!5e0!3m2!1sen!2sma!4v1717175411300!5m2!1sen!2sma"
        width="100%"
        height="200"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
  <Notify v-if="formSubmittedSuccessfully" />
</template>

<script>
import Notify from "@/components/Notify.vue";
const WEB3FORMS_ACCESS_KEY = "66325df3-acb4-462d-b789-7351e27480fb";

export default {
  components: {
    Notify,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      formSubmittedSuccessfully: false, // Track form submission status
    };
  },
  methods: {
    async submitForm() {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          access_key: WEB3FORMS_ACCESS_KEY,
          name: this.name,
          email: this.email,
          message: this.message,
        }),
      });
      const result = await response.json();
      if (result.success) {
        console.log(result);
        this.formSubmittedSuccessfully = true; // Set form submission status
        // Clear form fields after successful submission
        this.name = "";
        this.email = "";
        this.message = "";
      }
    },
  },
};
</script>

<style>
.info-icons {
  font-size: 1.5rem;
  margin-right: 10px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-section {
  flex: 1;
  width: 100%;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
}

.form-title h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.form-title p {
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.5;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.contact-input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  color: #000;
}

.ContactBtn {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.ContactBtn:hover {
  background-color: #333;
}

.info-section {
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.h2-info {
  font-size: 2rem;
  margin-bottom: 20px;
  color: orange;
}

.info-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-section li {
  font-size: 1rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-section li i {
  margin-right: 10px;
}

.info-section iframe {
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  height: 200px;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
  .contact-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .form-section,
  .info-section {
    width: 48%;
  }

  .form-title h1 {
    font-size: 2.5rem;
  }
}
</style>
