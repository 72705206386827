<template>
  <div v-if="show" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <form id="multistepsform" @submit.prevent="submitForm">
        <!-- progressbar -->
        <ul id="progressbar">
          <li :class="{ active: currentStep >= 1 }">Personal Details</li>
          <li :class="{ active: currentStep >= 2 }">Choose Activity</li>
          <li :class="{ active: currentStep >= 3 }">Group Size</li>
          <li :class="{ active: currentStep >= 4 }">Start Date</li>
        </ul>
        <!-- fieldsets -->
        <fieldset :style="{ display: currentStep === 1 ? 'block' : 'none' }">
          <h2 class="fs-title">Personal Details</h2>
          <h3 class="fs-subtitle">We will never sell it</h3>
          <label class="input-label">Full Name:</label>
          <input
            type="text"
            v-model="formData.fullName"
            placeholder="e.g. John Smith"
            required
          />
          <label class="input-label">Phone:</label>

          <input
            type="tel"
            v-model="formData.phone"
            placeholder="e.g. +212 687 687 687"
            required
          />
          <label class="input-label">Email:</label>

          <input
            type="email"
            v-model="formData.email"
            placeholder="e.g. john@example.com"
            required
          />
          <div class="button-group">
            <input
              type="button"
              @click="nextStep"
              class="next action-button"
              value="Next"
              :disabled="
                !formData.fullName || !formData.phone || !formData.email
              "
            />
          </div>
        </fieldset>
        <fieldset :style="{ display: currentStep === 2 ? 'block' : 'none' }">
          <h2 class="fs-title">Choose your activity</h2>
          <div class="radio-group">
            <label>
              <input
                type="radio"
                v-model="formData.activityType"
                value="Adventure"
              />
              Adventure
            </label>
            <label>
              <input
                type="radio"
                v-model="formData.activityType"
                value="Destination"
              />
              Destination
            </label>
            <label>
              <input
                type="radio"
                v-model="formData.activityType"
                value="Package"
              />
              Package
            </label>
          </div>
          <div v-if="formData.activityType">
            <select v-model="formData.activity" required>
              <option disabled value="">Select an option</option>
              <option
                v-for="option in activityOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <div class="button-group">
            <input
              type="button"
              @click="previousStep"
              class="previous action-button"
              value="Previous"
            />
            <input
              type="button"
              @click="nextStep"
              class="next action-button"
              value="Next"
              :disabled="!formData.activityType || !formData.activity"
            />
          </div>
        </fieldset>
        <fieldset :style="{ display: currentStep === 3 ? 'block' : 'none' }">
          <h2 class="fs-title">Group Size</h2>
          <input
            type="number"
            v-model="formData.groupSize"
            placeholder="Number of adults"
            max="50"
            required
          />
          <div class="checkbox-group">
            <label>
              <input type="checkbox" v-model="formData.hasChildren" />
              Children
            </label>
          </div>
          <div v-if="formData.hasChildren">
            <input
              type="number"
              v-model="formData.childrenCount"
              placeholder="Number of children"
              required
            />
          </div>
          <div class="button-group">
            <input
              type="button"
              @click="previousStep"
              class="previous action-button"
              value="Previous"
            />
            <input
              type="button"
              @click="nextStep"
              class="next action-button"
              value="Next"
              :disabled="
                !formData.groupSize ||
                (formData.hasChildren && !formData.childrenCount)
              "
            />
          </div>
        </fieldset>
        <fieldset :style="{ display: currentStep === 4 ? 'block' : 'none' }">
          <h2 class="fs-title">Start Date</h2>
          <input
            type="date"
            v-model="formData.startDate"
            placeholder="Start Date"
            required
          />
          <div v-if="formData.activityType === 'Destination'">
            <input
              type="date"
              v-model="formData.finishDate"
              placeholder="Finish Date"
              required
              :min="formData.startDate"
            />
          </div>
          <div class="button-group">
            <input
              type="button"
              @click="previousStep"
              class="previous action-button"
              value="Previous"
            />
            <input type="submit" class="submit action-button" value="Submit" />
          </div>
        </fieldset>
      </form>
    </div>
  </div>
  <Notify v-if="showNotification" />
</template>

<script>
import axios from "axios";
import Notify from "./Notify.vue";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    prefillData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close"],
  components: { Notify },
  data() {
    return {
      currentStep: 1,
      showNotification: false,
      formData: {
        fullName: "",
        phone: "",
        email: "",
        activityType: "",
        activity: "",
        groupSize: "",
        hasChildren: false,
        childrenCount: "",
        startDate: this.tomorrowDate(),
        finishDate: "",
      },
      allActivityOptions: {
        Adventure: [
          "Quad Biking",
          "Buggy Driving",
          "Camel Ride",
          "Hot Air Balloon",
          "Paragliding",
          "Karting",
          "Paintball",
        ],
        Destination: [
          "Marrakech",
          "Essaouira",
          "Chefchaouen",
          "Agadir",
          "Ouarzazat",
          "Sahara Desert",
          "Fes",
          "Casablanca",
        ],
        Package: [
          "Family Package",
          "Honeymoon Package",
          "Corporate Package",
          "Adrenal Package",
        ],
      },
    };
  },
  watch: {
    prefillData: {
      immediate: true,
      handler(newData) {
        if (Object.keys(newData).length) {
          this.formData = { ...this.formData, ...newData };
        }
      },
    },
  },
  methods: {
    tomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    },
    nextStep() {
      if (this.currentStep < 4) {
        this.currentStep += 1;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    submitForm() {
      axios
        .post("https://nomadthrills.com/backend/submit-form", this.formData)
        .then((response) => {
          this.resetForm();
          this.$emit("close");
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 3000);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          alert("Error submitting form");
        });
    },
    resetForm() {
      this.currentStep = 1;
      this.formData = {
        fullName: "",
        phone: "",
        email: "",
        activityType: "",
        activity: "",
        groupSize: "",
        hasChildren: false,
        childrenCount: "",
        startDate: this.tomorrowDate(),
        finishDate: "",
      };
    },
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    activityOptions() {
      return this.formData.activityType
        ? this.allActivityOptions[this.formData.activityType]
        : [];
    },
  },
};
</script>
<style scoped>
#multistepsform {
  width: 90%;
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
  position: relative;
}

fieldset {
  background: white;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  position: relative;
}
fieldset:not(:first-of-type) {
  display: none;
}

.input-label {
  font-size: 12px;
  color: orange;
  font-weight: bold;
}

input,
textarea,
select {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: #2c3e50;
  font-size: 13px;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #679b9b;
  outline: none;
  color: #637373;
}

.radio-group,
.checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.radio-group label,
.checkbox-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.radio-group label input,
.checkbox-group label input {
  margin-right: 10px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.action-button {
  width: 100px;
  background: #000;
  font-weight: bold;
  color: #fff;
  transition: 150ms;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

.action-button:hover,
.action-button:focus {
  box-shadow: 0 0 0 2px #f08a5d, 0 0 0 3px #ff976a;
  color: #fff;
}

.fs-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}

#progressbar li {
  list-style-type: none;
  color: #679b9b;
  text-transform: uppercase;
  font-size: 12px;
  width: 24%;
  text-align: center;
  position: relative;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #fff;
  background: #679b9b;
  border-radius: 3px;
  margin: 0 auto 5px auto;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #679b9b;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1;
}

#progressbar li:first-child:after {
  content: none;
}

#progressbar li.active {
  color: #ff9a76;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #ff9a76;
  color: white;
}

/* Modal styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 600px;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 600px) {
  #multistepsform {
    width: 100%;
    margin: 20px auto;
  }

  .button-group {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
    margin: 10px 0;
  }

  #progressbar {
    flex-direction: column;
    align-items: center;
  }

  #progressbar li {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
