<template>
  <div class="testimonials-section">
    <h3>Traveller's Experience</h3>
    <h2>
      Real Travel Stories<br />
      from Real People
    </h2>
    <div class="testimonials">
      <div
        class="testimonial"
        v-for="(testimonial, index) in testimonials"
        :key="index"
      >
        <i class="fas fa-quote-left quote-icon"></i>
        <p class="quote">"{{ testimonial.quote }}"</p>
        <div class="user-info">
          <img
            :src="testimonial.image"
            :alt="testimonial.name"
            class="user-image"
          />
          <div class="user-details">
            <h4>{{ testimonial.name }}</h4>
            <p>{{ testimonial.occupation }}</p>
            <div class="rating">
              <i
                v-for="star in 5"
                :key="star"
                class="fa"
                :class="{
                  'fa-star': star <= testimonial.rating,
                  'fa-star-o': star > testimonial.rating,
                }"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      testimonials: [
        {
          quote:
            "Quad biking through the desert was exhilarating! The local tea break added a charming touch to an already perfect day.",
          name: "Zakaria Gerrouji",
          rating: 5,
          image: require("@/assets/profiles/profile_pic_1.jpg"),
        },
        {
          quote:
            "The camel ride at sunset was mesmerizing! The guides were friendly, and the views were spectacular. Truly memorable!",
          name: "Maria Frostfern",
          rating: 5,
          image: require("@/assets/profiles/profile_pic_3.jpg"),
        },
        {
          quote:
            "The buggy adventure was incredible! The team was professional, and the scenic desert views were breathtaking.",
          name: "Mehdi Idrissi radi",
          rating: 5,
          image: require("@/assets/profiles/profile_pic_2.jpg"),
        },
        {
          quote:
            "The team was professional and accommodating, and the destinations were even more beautiful than I imagined. I will definitely be using this company again in the future.",
          name: "Amy Oconnell",
          rating: 5,
          image: require("@/assets/profiles/profile_pic_4.jpg"),
        },
      ],
    };
  },
};
</script>
<style>
.testimonials-section {
  padding: 40px 20px;
  background-color: #fff;
}

.testimonials-section h3 {
  color: #dc812f;
  margin-bottom: 10px;
  padding-left: 35px;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  padding-left: 35px;
}
.quote-icon {
  font-size: 1.5rem;
  color: #dc812f;
}

.testimonials {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.testimonial {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  max-width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quote {
  font-style: italic;
  margin-bottom: 15px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details h4 {
  margin: 0;
  font-size: 1rem;
}

.user-details p {
  margin: 0;
  color: #777;
}

.rating .fa {
  color: gold;
  margin-right: 2px;
}
@media (max-width: 768px) {
  .testimonials-section h3 {
    font-size: 1rem;
  }
  .testimonials-section h2 {
    font-size: 1.9rem;
  }
}
</style>
