<template>
  <!-- Hero Section (Problem) -->
  <HeroSection />
  <ThingsToDo />
  <Testimonials />
  <AboutSection />
  <FaqSection />
  <CtaSection />
</template>

<script>
import HeroSection from "../components/HeroSection.vue";
import ThingsToDo from "../components/Home/ThingsToDo.vue";
import Testimonials from "../components/Home/Testimonials.vue";
import AboutSection from "../components/Home/AboutSection.vue";
import FaqSection from "../components/Home/FaqSection.vue";
import CtaSection from "../components/Home/CtaSection.vue";

export default {
  components: {
    HeroSection,
    ThingsToDo,
    Testimonials,
    AboutSection,
    FaqSection,
    CtaSection,
  },
  data() {
    return {};
  },
};
</script>
