<template>
  <div class="two-column-view">
    <!-- First column (left) -->
    <div class="first-column">
      <!-- Big headline with smaller one on top -->
      <h4 class="first_h4">Feel tired & ready to party?</h4>
      <h1>
        Vacation of<br />
        your dream? <br /><span>Sure.</span>
      </h1>

      <!-- CTA button -->
      <button class="cta-button" @click="openFormModal">
        Book a trip now
        <i class="fa-solid fa-right-to-bracket"></i>
      </button>

      <!-- Three columns with destination information -->
      <h6>
        Top rated<br />
        destinations
      </h6>
      <div class="destination-columns">
        <div
          v-for="(destination, index) in popularDestinations"
          :key="index"
          class="destination-column"
        >
          <div class="destination">
            <h4>{{ destination.name }}</h4>
            <p>
              <i class="fa-solid fa-star"></i>
              {{ destination.rating }}
            </p>
            <button @click="seeMore(destination)" class="see-more-btn">
              See more <i class="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Second column (right) -->
    <div class="second-column">
      <img src="../assets/imageCollection.png" alt="" class="imageCollection" />
    </div>
  </div>

  <MultiStepForm
    :show="showModal"
    :prefillData="modalData"
    @close="closeFormModal"
  />
</template>

<script>
import MultiStepForm from "./MultiStepForm.vue";

export default {
  data() {
    return {
      popularDestinations: [
        { name: "Marrakech", rating: 5.0 },
        { name: "Chefchaouen", rating: 4.7 },
        { name: "Fes", rating: 4.6 },
      ],
      showModal: false, // Control the visibility of the modal
      modalData: {}, // Data to pre-fill the form
    };
  },
  components: {
    MultiStepForm,
  },
  methods: {
    exploreDestinations() {
      // Action to explore destinations
    },
    seeMore(destination) {
      this.$router.push("/destinations");
    },
    getImageClass(index) {
      switch (index) {
        case 0:
          return "image-wide";
        case 1:
          return "image-medium";
        case 2:
          return "image-large";
        case 3:
          return "image-small image-rounded";
        default:
          return "";
      }
    },
    openFormModal() {
      this.modalData = {}; // Clear modal data for new entry
      this.showModal = true;
    },
    closeFormModal() {
      this.showModal = false;
    },
  },
};
</script>

<style>
span {
  color: orange;
}
.first_h4 {
  margin-bottom: 30px;
}
h1 {
  font-size: 3.5rem;
  font-family: "poetsense", sans-serif;
  font-weight: bold;
}
h6 {
  margin-bottom: 3%;
  color: #c1c1c1;
}
.fa-right-to-bracket {
  margin-left: 15px;
}
.fa-star {
  color: orange;
}
.see-more-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}
/* Styling for the two-column layout */
.two-column-view {
  display: flex;
  flex-wrap: wrap;
}

.first-column {
  flex: 1;
  padding: 20px;
  margin-left: 80px;
  margin-top: 6.5%;
}

.second-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styling for destination columns */
.destination-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.destination-column {
  flex: 1;
  margin-right: 10px;
  min-width: 150px;
}

.destination {
  margin-bottom: 20px;
}

/* Styling for images */
.imageCollection {
  margin-top: 6.1%;
  height: 90%;
  width: 70%;
  max-width: 500px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .first_h4 {
    font-size: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .fa-right-to-bracket {
    margin-left: 5px;
  }

  .two-column-view {
    flex-direction: column;
    margin-top: 80px;
  }

  .first-column {
    margin: 0 auto;
    padding: 10px;
    text-align: center;
  }

  .second-column {
    margin: 0 auto;
    padding: 10px;
    text-align: center;
  }

  .destination-columns {
    flex-direction: row; /* Keep the columns in the same row */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Enable horizontal scrolling */
  }

  .destination-column {
    margin-bottom: 10px;
    flex: 1 1 150px; /* Prevent the columns from shrinking */
    width: auto;
    min-width: 110px;
    max-width: 140px;
  }
}
</style>
