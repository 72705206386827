<template>
  <div class="card-list">
    <SingleCard
      v-for="(image, index) in displayedImages"
      :key="image.id"
      :image="image"
    />
  </div>
  <button
    @click="toggleShowAll"
    class="seeAllBtn"
    :class="{ showLess: showAll }"
  >
    {{ showAll ? "Show Less" : "See All" }}
  </button>
</template>

<script>
import SingleCard from "./SingleCard.vue";

export default {
  props: ["filteredCategory"],
  components: {
    SingleCard,
  },
  data() {
    return {
      images: [
        {
          id: 1,
          url: "Marrakech.jpg",
          rating: 4.8,
          price: "$100",
          city: "Marrakech",
          place: "Koutoubia Mosque",
          category: ["monuments", "souk", "recommend", "desert"],
        },
        {
          id: 2,
          url: "Essaouira.jpg",
          rating: 4.8,
          price: "$90",
          city: "Essaouira",
          place: "Mersa hhh",
          category: ["monuments", "beach"],
        },
        {
          id: 3,
          url: "Chefchaouen.jpg",
          rating: 4.8,
          price: "$150",
          city: "Chefchaouen",
          place: "Neighberhoods",
          category: ["nature", "recommend"],
        },
        {
          id: 4,
          url: "Paradise_Vally_Agadir.jpg",
          rating: 4.8,
          price: "$100",
          city: "Agadir",
          place: "Paradise Vally",
          category: ["beach", "nature"],
        },
        {
          id: 5,
          url: "Kasba_Ait_Ben_Haddou.jpg",
          rating: 4.8,
          price: "$250",
          city: "Ouarzazat",
          place: "Kasbah Ait Ben Haddou",
          category: ["monuments", "recommend", "desert", "nature"],
        },
        {
          id: 6,
          url: "Merzouga_Camp.jpg",
          rating: 4.8,
          price: "$400",
          city: "Sahara Desert",
          place: "Merzouga",
          category: ["recommend", "nature", "desert"],
        },
        {
          id: 7,
          url: "Fes.jpg",
          rating: 4.8,
          price: "$220",
          city: "Fes",
          place: "Dar Dbaagh",
          category: ["recommend", "nature", "monuments", "souk"],
        },
        {
          id: 8,
          url: "Casablanca.jpg",
          rating: 4.8,
          price: "$100",
          city: "Casablanca",
          place: "Hassan II Mosque",
          category: ["monuments", "beach"],
        },
      ],
      showAll: false,
    };
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
  },
  computed: {
    displayedImages() {
      if (this.filteredCategory === "All") {
        return this.showAll ? this.images : this.images.slice(0, 4);
      } else {
        const filteredImages = this.images.filter((image) =>
          image.category.includes(this.filteredCategory)
        );
        return this.showAll ? filteredImages : filteredImages.slice(0, 4);
      }
    },
  },
};
</script>

<style>
.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
.seeAllBtn {
  margin: 20px 0;
  color: #fff;
  background-color: #de8738;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
}
.seeAllBtn:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}

.showLess {
  background-color: transparent;
  color: #a6a6a6;
  text-decoration: underline;
}
</style>
