<template>
  <div class="activities-list">
    <div class="activities-container">
      <ActivityCard
        v-for="(activity, index) in activities"
        :key="index"
        :activity="activity"
        @click="showModal(activity)"
      />
    </div>
    <ModalActivity
      :show="isModalVisible"
      :activity="selectedActivity"
      @close="isModalVisible = false"
    />
  </div>
</template>

<script>
import ActivityCard from "./ActivityCard.vue";
import ModalActivity from "./ModalActivity.vue";

export default {
  name: "ActivitiesList",
  components: {
    ActivityCard,
    ModalActivity,
  },
  data() {
    return {
      activities: [
        {
          image: "buggy_1.jpg",
          additionalImages: ["buggy_2.jpeg", "buggy_4.jpg"],
          title: "Desert Buggy Adventure",
          description: "Experience the thrill of desert buggy riding",
          price: "$100",
          category: "Adventure",
        },
        {
          image: "quad_2.jpg",
          additionalImages: ["quad_1.jpg", "quad_3.jpg"],
          title: "Quad Bike Adventure",
          description: "Experience the thrill of quad biking",
          price: "$35",
          category: "Adventure",
        },
        {
          image: "camel_1.jpg",
          additionalImages: ["camel_2.jpg", "camel_3.jpg"],
          title: "Camel Riding",
          description: "Experience the thrill of camel riding",
          price: "$15",
          category: "Relaxation",
        },
        {
          image: "tourGuide.jpg",
          additionalImages: ["guide_2.jpg", "guide_3.jpg"],
          title: "Explore Marrakech",
          description: "Discover the beauty of Marrakech",
          price: "$60",
          category: "Cultural",
        },
        {
          image: "balloon_2.jpg",
          additionalImages: ["balloon_1.jpg", "balloon_3.jpg"],
          title: "Hot Air Balloon",
          description: "Experience the thrill of a hot air balloon ride",
          price: "$170",
          category: "Nature",
        },
        {
          image: "paragliding.jpg",
          additionalImages: ["paragliding_2.jpg", "paragliding_3.jpg"],
          title: "Paragliding",
          description: "Experience the thrill of paragliding",
          price: "$160",
          category: "Adventure",
        },
        {
          image: "karting.jpg",
          additionalImages: ["karting_2.jpg", "karting_3.jpg"],
          title: "Go Karting",
          description: "Experience the thrill of go-karting",
          price: "$50",
          category: "Adventure",
        },
        {
          image: "paintball.jpg",
          additionalImages: ["paintball_2.jpg", "paintball_3.jpg"],
          title: "Paintball",
          description: "Experience the thrill of paintball",
          price: "$25",
          category: "Adventure",
        },
        // Add more activities as needed
      ],
      isModalVisible: false,
      selectedActivity: null,
    };
  },
  methods: {
    showModal(activity) {
      this.selectedActivity = activity;
      this.isModalVisible = true;
    },
  },
};
</script>

<style>
.activities-list {
  width: 80%;
  margin: 0 auto;
}
.activities-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
</style>
