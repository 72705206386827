<template>
  <div class="notification">
    <div class="notification_body">
      <i class="fa-regular fa-circle-check notification_icon"></i>
      Your form has been sumbited successfuly!
    </div>
    <div class="notification_progress"></div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style>
  .notification{
    position: absolute;
    width: max-content;
    left: 0;
    right: 0;
    bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    background-color: #313e2c;
    color: #aaec8a;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    animation: fade-in 3s linear;
  }
  .notification_icon{
    height: 26px;
    width: 26px;
    margin-right: 4px;
  }
  .notification_body{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 8px;
  }
  .notification_progress{
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: calc(100% - 8px);
    height: 3px;
    transform: scaleX(0);
    transform-origin: left;
    background: linear-gradient(to right, #313e2c, #aaec8a);
    border-radius: inherit;
    animation: progress 2.5s 0.3s linear;
  }
  @keyframes fade-in {
    5% {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    95% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes progress {
    to {
      transform: scaleX(1);
    }
  }
</style>