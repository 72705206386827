<template>
  <div class="destinations">
    <h5>EXPLORE YOUR DREAM PLACE</h5>
    <h1>Find Your Dream Destination</h1>
    <h4>
      Find Your Perfect Destination-The Ultimate Guide to Your Dream Destination
    </h4>
    <BookBar />
    <FilterNav @filter="handleFilter" />
    <CardList :filteredCategory="filteredCategory" />
  </div>
</template>

<script>
import BookBar from "@/components/Destination/BookBar.vue";
import FilterNav from "@/components/Destination/FilterNav.vue";
import CardList from "@/components/Destination/CardList.vue";

export default {
  components: {
    BookBar,
    FilterNav,
    CardList,
  },
  data() {
    return {
      filteredCategory: "All", //Initially set to 'All'
    };
  },
  methods: {
    handleFilter(category) {
      this.filteredCategory = category;
    },
  },
};
</script>

<style>
.destinations {
  margin-top: 90px;
  text-align: center;
  padding: 0 10%;
}
.destinations h1 {
  font-weight: bold;
  font-size: 2.5rem;
}
.destinations h5 {
  color: #de8738;
  margin-bottom: 20px;
}
.destinations h4 {
  color: #a6a6a6;
  margin: 30px auto;
  font-size: 0.9rem;
}
</style>
