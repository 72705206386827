<template>
  <div class="contact-page">
    <ContactForm />
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  components: {
    ContactForm,
  },
};
</script>

<style>
.contact-page {
  margin-top: 10%;
  padding: 20px;
}
</style>
