<template>
  <div class="faq-container">
    <div class="faq-image-container">
      <img
        :src="require('@/assets/FAQ.jpg')"
        alt="FAQ Image"
        class="faq-image"
      />
    </div>
    <div class="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div class="faq-item" v-for="(faq, index) in faqs" :key="index">
        <div class="faq-question" @click="toggleFaq(index)">
          {{ faq.question }}
          <span class="toggle-icon">
            <i
              :class="{
                'fa-chevron-down': !faq.open,
                'fa-chevron-up': faq.open,
              }"
            ></i>
          </span>
        </div>
        <div class="faq-answer" v-if="faq.open">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: "What is your return policy?",
          answer: "Our return policy is...",
          open: false,
        },
        {
          question: "How do I track my order?",
          answer: "You can track your order by...",
          open: false,
        },
        {
          question: "Can I purchase items in bulk?",
          answer: "Yes, bulk purchases are available...",
          open: false,
        },
      ],
    };
  },
  methods: {
    toggleFaq(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
  },
};
</script>
<style>
.faq-container {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  background-color: #f9f9f9;
}

.faq-image-container {
  flex: 1;
  padding: 0 40px;
}

.faq-image {
  width: 80%;
  height: 80%;
  border-radius: 10px;
}

.faq-section {
  flex: 2;
  padding: 20px;
  border-radius: 10px;
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.toggle-icon {
  margin-left: 10px;
}

.faq-answer {
  margin-top: 10px;
  padding-left: 20px;
  color: #555;
}

.fa-chevron-down::before,
.fa-chevron-up::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-chevron-up::before {
  content: "\f077";
}
@media (max-width: 768px) {
  .faq-container {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center; /* Center align items */
  }

  .faq-image-container {
    width: 100%; /* Full width for the image container on mobile */
    padding: 0;
    margin-bottom: 20px; /* Add space below image */
  }

  .faq-image {
    width: 100%; /* Full width within its container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
  }

  .faq-section {
    width: 100%; /* Full width for the section on mobile */
    padding: 10px; /* Adjust padding for mobile */
  }

  .faq-section h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .faq-item {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }

  .faq-question {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .toggle-icon {
    margin-left: 10px;
  }

  .faq-answer {
    padding-left: 20px;
    margin-top: 10px;
    color: #555;
  }
}
</style>
