<template>
  <div class="activities-page">
    <!-- First Section: Banner -->
    <section class="banner-section">
      <div class="banner-overlay">
        <h1>Discover Amazing Activities</h1>
      </div>
    </section>

    <!-- Second Section: Categories -->
    <section class="categories-section">
      <h2>Categories</h2>
      <div class="categories-container">
        <div
          v-for="category in categories"
          :key="category.id"
          class="category-card"
        >
          <img
            :src="category.image"
            :alt="category.title"
            class="category-image"
          />
          <h3>{{ category.title }}</h3>
          <p>{{ category.description }}</p>
          <button class="btn">See Activities</button>
        </div>
      </div>
    </section>

    <!-- Third Section: Deals -->
    <section class="deals-section">
      <h2>Our Packages</h2>
      <div class="deals-container">
        <div v-for="deal in deals" :key="deal.id" class="deal-card">
          <img :src="deal.image" :alt="deal.title" class="deal-image" />
          <div class="deal-details">
            <h3>{{ deal.title }}</h3>
            <p>{{ deal.details }}</p>
            <button class="btn">View Details</button>
          </div>
        </div>
      </div>
    </section>

    <!-- Fourth Section: Activities -->
    <section class="activities-section">
      <h2>Our Activities</h2>
      <ActivitiesList />
    </section>
  </div>
</template>

<script>
import ActivitiesList from "../components/Activities/ActivitiesList.vue";
export default {
  data() {
    return {
      categories: [
        {
          id: 1,
          image: require("@/assets/quad_3.jpg"),
          title: "Adventure",
          description: "Exciting adventure activities",
        },
        {
          id: 2,
          image: require("@/assets/tourGuide.jpg"),
          title: "Cultural",
          description: "Experience the culture",
        },
        {
          id: 3,
          image: require("@/assets/camel_2.jpg"),
          title: "Relaxation",
          description: "Relaxing and soothing activities",
        },
        {
          id: 4,
          image: require("@/assets/balloon_3.jpg"),
          title: "Nature",
          description: "Explore the beauty of nature",
        },
      ],
      deals: [
        {
          id: 1,
          image: require("@/assets/buggy_2.jpeg"),
          title: "Weekend Getaway",
          details: "Enjoy a weekend full of activities and relaxation",
        },
        {
          id: 2,
          image: require("@/assets/quad_3.jpg"),
          title: "Family Package",
          details: "Fun activities for the whole family",
        },
        {
          id: 3,
          image: require("@/assets/camel_2.jpg"),
          title: "Adventure Package",
          details: "Thrilling adventures for adrenaline junkies",
        },
      ],
    };
  },
  components: {
    ActivitiesList,
  },
};
</script>

<style>
.activities-page {
  font-family: Arial, sans-serif;
}

/* First Section: Banner */
.banner-section {
  position: relative;
  background: url("@/assets/hero_img.jpg") no-repeat center center/cover;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-overlay {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.banner-section h1 {
  font-size: 2.5rem;
  text-align: center;
}

/* Second Section: Categories */
.categories-section {
  padding: 40px 20px;
  text-align: center;
}

.categories-section h2,
.deals-section h2,
.activities-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.categories-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.category-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 22%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.category-card:hover {
  transform: scale(1.05);
}

.category-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}

.category-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.category-card p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.category-card button:hover,
.deal-details button:hover {
  background-color: #d9d9db;
}

/* Third Section: Deals */
.deals-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #f2f2f2;
}

.deals-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.deal-card {
  background: #fff;
  border-radius: 10px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
}

.deal-card:hover {
  transform: scale(1.05);
}

.deal-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.deal-details {
  padding: 20px;
}

.deal-details h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.deal-details p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

/* Fourth Section: Activities */
.activities-section {
  padding: 40px 20px;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .banner-section {
    height: 300px;
  }

  .banner-overlay {
    padding: 10px;
  }

  .banner-section h1 {
    font-size: 1.5rem;
  }

  .categories-container,
  .deals-container {
    flex-direction: column;
    align-items: center;
  }

  .category-card,
  .deal-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .category-card h3,
  .deal-details h3 {
    font-size: 1.25rem;
  }

  .category-card p,
  .deal-details p {
    font-size: 0.875rem;
  }

  .category-image {
    width: 100px;
    height: 100px;
  }

  .deal-image {
    height: 150px;
  }
}
</style>
