<template>
  <footer>
    <div class="row">
      <div class="col">
        <div class="logo footer_logo">
          <!-- Your logo or brand name -->
          <p @click="goHome()" class="nav-logo">
            <span class="first-part-logo">NOMAD</span> THRILLS
          </p>
        </div>
        <!-- <img src="#" alt="footer_logo" class="footer_logo" /> -->
        <p>
          Discover unforgettable adventures with NOMAD THRILLS. From sunset
          camel rides to thrilling quad biking, explore Marrakech and travel to
          breathtaking destinations.
        </p>
      </div>
      <div class="col">
        <h3>
          Office
          <div class="underline"><span></span></div>
        </h3>
        <p>Marrakech, Morocco</p>
        <p>Douar Ouled Messaoud, Wahat Sidi Brahim, El Ouidane</p>
        <p class="email-id">info@nomadthrills.com</p>
        <h4>+212 665 663 933</h4>
        <h4>+212 665 663 833</h4>
        <h4>+212 665 663 959</h4>
      </div>
      <div class="col">
        <h3>
          Links
          <div class="underline"><span></span></div>
        </h3>
        <ul>
          <li><router-link to="/">Discover</router-link></li>
          <li>
            <router-link to="/activities" class="nav-link"
              >Activities</router-link
            >
          </li>
          <li><router-link to="/destinations">Destinations</router-link></li>
          <li><router-link to="/contact">Contact Us</router-link></li>
        </ul>
      </div>
      <div class="col">
        <h3>
          Newsletter
          <div class="underline"><span></span></div>
        </h3>
        <form>
          <i class="far fa-regular fa-envelope"></i>
          <input type="email" placeholder="Enter your email" required />
          <button type="submit">
            <i class="far fa-solid fa-arrow-right"></i>
          </button>
        </form>
        <div class="social-icons">
          <a
            href="https://www.facebook.com/profile.php?id=61560503783000"
            target="_blank"
            title="Facebook"
            ><i class="fa-brands fa-facebook"></i
          ></a>
          <a
            href="https://www.instagram.com/nomad_thrills01/"
            target="_blank"
            title="Instagram"
            ><i class="fa-brands fa-instagram"></i
          ></a>
          <a href="#" title="X-twitter"
            ><i class="fa-brands fa-square-x-twitter"></i
          ></a>
          <a
            href="https://www.pinterest.fr/nomadthrills/"
            target="_blank"
            title="Pinterest"
            ><i class="fa-brands fa-pinterest"></i
          ></a>
        </div>
      </div>
    </div>
    <hr />
    <p class="copyright">Nomad Thrills &copy; 2024 - All Rights Reserved</p>
  </footer>
</template>

<script>
export default {};
</script>

<style>
footer {
  width: 100%;
  background: linear-gradient(to right, #00093c, #2d0b00);
  color: #fff;
  padding: 100px 0 30px;
  font-size: 13px;
  line-height: 20px;
  border-top-left-radius: 125px;
}
.row {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.col {
  flex-basis: 25%;
  padding: 10px;
}
.col:nth-child(2),
.col:nth-child(3) {
  flex-basis: 15%;
}
.footer_logo {
  width: 80px;
  margin-bottom: 30px;
}
.footer_logo .first-part-logo {
  color: #fff;
}
.col h3 {
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
  color: orange;
}
.email-id {
  width: fit-content;
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
}
footer .row .col ul li {
  margin-bottom: 12px;
}
footer .row .col ul li a {
  text-decoration: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 50px;
}
footer .row .col ul li a:hover {
  background: #fff;
  color: #000;
}
form {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
}
form .far {
  font-size: 18px;
  margin-right: 10px;
}
form input {
  width: 100%;
  background: transparent;
  color: #ccc;
  border: 0;
  outline: none;
}
form button {
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
form button .fa-arrow-right {
  font-size: 16px;
  color: #ccc;
}
.social-icons .fa-brands {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #000;
  background: #fff;
  margin-right: 15px;
  cursor: pointer;
}
hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
}
.copyright {
  text-align: center;
}
.underline {
  width: 100%;
  height: 5px;
  background: #767676;
  border-radius: 3px;
  position: absolute;
  top: 25px;
  left: 0;
  overflow: hidden;
}
.underline span {
  width: 15px;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 10px;
  animation: moving 2s linear infinite;
}
@keyframes moving {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}
@media (max-width: 700px) {
  footer {
    bottom: unset;
  }
  .col {
    flex-basis: 100%;
  }
  .col:nth-child(2),
  .col:nth-child(3) {
    flex-basis: 100%;
  }
}
</style>
