<template>
  <div class="cta-section">
    <div class="cta-content">
      <h2>Get Every Tour &<br> Packages Update</h2>
      <div class="cta-form">
        <input type="email" placeholder="Enter your email" v-model="email" />
        <button @click="subscribe">Subscribe</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    subscribe() {
      if (this.email) {
        // Handle the subscription logic here
        console.log(`Subscribed with email: ${this.email}`);
      } else {
        alert("Please enter a valid email address.");
      }
    },
  },
};
</script>

<style>
.cta-section {
  position: relative;
  background-image: url("@/assets/camel_2.jpg");
  background-size: cover;
  background-position: center;
  padding: 60px 20px;
  border-radius: 10px;
  color: white;
  /* text-align: center; */
  margin: 20px;
}

.cta-content {
  max-width: 400px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 40px 20px;
  border-radius: 10px;
  color: #000;
}

.cta-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.cta-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.cta-form input[type="email"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  flex: 1;
}

.cta-form button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: orange;
  color: #000;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .cta-form {
    flex-direction: column;
  }

  .cta-form input[type="email"] {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
</style>
