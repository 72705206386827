<template>
  <div class="activity-card" @click="showDetails">
    <img
      :src="getImageUrl(activity.image)"
      :alt="activity.title"
      class="activity-image"
    />
    <h3 class="activity-title">{{ activity.title }}</h3>
    <p class="activity-description">{{ activity.description }}</p>
    <div class="activity-footer">
      <span class="activity-price">{{ activity.price }}</span>
      <button class="activity-button" @click.stop="addToCart(activity)">
        <i class="fa fa-shopping-cart"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityCard",
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addToCart(activity) {
      // Implement the logic to add the activity to the cart
      console.log(`Added ${activity.title} to cart`);
    },
    getImageUrl(imagePath) {
      return require(`@/assets/${imagePath}`);
    },
    showDetails() {
      this.$emit("click");
    },
  },
};
</script>

<style>
.activity-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.activity-image {
  width: 100%;
  height: 60%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.activity-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
}

.activity-description {
  color: #666;
  margin: 10px 0;
}

.activity-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.activity-price {
  font-size: 1.2em;
  font-weight: bold;
}

.activity-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
</style>
